import React, {Component} from "react"
import {Button, Col, Row, Image, Input, Divider, Modal} from "antd"
import currencyFormat from "../../helper/currencyFormat"
import Form from "antd/lib/form"
import AreaProvinceSelect from "../area/AreaProvinceSelect"
import AreaCitySelect from "../area/AreaCitySelect"
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService"
import {liveBqLogService} from "../../services/track/LiveBqLogService"

class PurchasePlanSection extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)
        this.initState = {
            freezeStatus: false,
        }
        this.state = {...this.initState}
    }

    componentDidMount() {

    }

    onFieldChange = <T extends keyof Pick<any, "fullName" | "idCardNumber" | "phoneNumber" | "email" | "customerPostalCode" | "customerHamlet" | "customerNeighbourhood" | "customerAddress">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "fullName":
                currentState.fullName = value
                break
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '')
                break
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '')
                break
            case "email":
                currentState.email = value
                break
            case "customerPostalCode":
                currentState.customerPostalCode = value
                break
            case "customerHamlet":
                currentState.customerHamlet = value
                break
            case "customerNeighbourhood":
                currentState.customerNeighbourhood = value
                break
            case "customerAddress":
                currentState.customerAddress = value?.toUpperCase()
                break
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = ""
                }
            }
        }

        this.setState({
            ...currentState,
        })
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            customerProvinceCode: data.value,
            customerProvinceName: data.children,
            customerCityCode: "",
            customerCityName: null,
        })
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            customerCityCode: data.value,
            customerCityName: data.children,
        })
    }

    onSubmit = async () => {
        let warnNotice = ""
        if (!this.state.fullName) {
            warnNotice += "Nama Lengkap, "
        }
        if (!this.state.phoneNumber) {
            warnNotice += "Nomor Telpon, "
        }
        if (!this.state.customerProvinceCode) {
            warnNotice += "Provinsi, "
        }
        if (!this.state.customerCityCode) {
            warnNotice += "Kota, "
        }

        if (warnNotice !== "") {
            const objError = warnNotice.split(",")
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>
                    })}
                </div>)
            })
            return false
        }

        this.setState({
            submitButton: true,
            freezeStatus: true,
        })

        try {
            await liveBqLogService.sendTrackLog({
                companyCode: this.props.companyData?.code,
                cityGroup: this.props.areaCode,
                sourceUrl: window.location.href,
                site: window.location.host,
                category: "submit_form",
                name: "landing_page",
                parameter: this.state.phoneNumber,
                vehicle: {
                    brand_uuid: this.props.dataVariantColor.brand_uuid,
                    brand_name: this.props.dataVariantColor.brand_name,
                    model_name: this.props.dataVariantColor.model_name,
                    model_uuid: this.props.dataVariantColor.model_uuid,
                    variant_name: this.props.dataVariantColor.variant_name,
                    variant_code: this.props.dataVariantColor.variant_code,
                    color_code: this.props.dataVariantColor.color_code,
                    color_name: this.props.dataVariantColor.color_name,
                },
                id_card_order_maker: {
                    full_name: this.state.fullName?.trim()?.toUpperCase(),
                },
                address_order_maker: {
                    province_code: this.state.customerProvinceCode,
                    province_name: this.state.customerProvinceName,
                    city_code: this.state.customerCityCode,
                    city_name: this.state.customerCityName,
                }
            })
            Modal.success({
                title: 'Proses Sukses',
                content: 'data telah kami terima dan akan kami proses. terimakasih telah melakukan input form.'
            })
            setTimeout(function () {
                window.location.reload()
            }, 2000)
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            })
            this.setState({
                submitButton: false,
                freezeStatus: false
            })
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"})
            return
        }
        this.setState({showModalUploadIdCardOwner: true})
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"})
            await this.promisedSetState({
                showOldUploadRawImage: true,
            })
            return
        }

        const dataUpload = {
            id_image: this.state.idCardNumber,
            image_data: this.state.base64IdCardOwner,
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload)
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"})
                return
            }
        }

        await this.promisedSetState({
            freezeStatus: true,
        })

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    })
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadIdCardOwner: false,
                })
            })
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            })
            await this.promisedSetState({
                freezeStatus: false
            })
        }
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardOwner: base64DataImagePng,
        })
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            })
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            })
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            })
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div>
                    <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7}}>FORM INQUIRY</div>
                    <div style={{textAlign: "center", fontSize: "100%", color: "gray"}}>Silakan isi data diri Anda pada form di bawah ini</div>
                </div>
                <Row>
                    <Col style={{paddingTop: 50}} xxl={{span: 24}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col span={18}>
                                <Row style={{paddingTop: 18}}>
                                    <Col span={24} style={{fontSize: "120%"}}>{this.props.dataVariantColor?.variant_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</Col>
                                </Row>
                                <Row style={{paddingTop: 5}}>
                                    <Col span={24} className={`text-small-grey-nopadding`}>Color: {this.props.dataVariantColor?.color_name}</Col>
                                </Row>
                                <Row style={{paddingTop: 5}}>
                                    <Col span={24} className={`text-small-grey-nopadding`}>OTR: {currencyFormat(this.props.dataVariantColor?.price)}</Col>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Image style={{width: "100%"}} src={this.props.dataVariantColor?.url_image}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{paddingTop: 20}} xxl={{span: 24}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                                    <Input value={this.state.fullName} onChange={event => this.onFieldChange("fullName", event.target.value)} placeholder="Masukan Nama Sesuai KTP Pemilik"/>
                                </Form.Item>
                            </Col>
                            <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Nomor Telpon *</i>
                                    <Input
                                        value={this.state.phoneNumber}
                                        maxLength={17}
                                        placeholder="Format: 08xxxxxxxx"
                                        onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Provinsi Pemilik *</i>
                                    <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceName={this.state.customerProvinceName}/>
                                </Form.Item>
                            </Col>
                            <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Kota Pemilik *</i>
                                    <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.customerProvinceCode} cityName={this.state.customerCityName}/>
                                </Form.Item>
                            </Col>

                            <Divider/>

                            <Button size={"large"} block={true} onClick={() => this.onSubmit()} loading={this.state.submitButton} style={{backgroundColor: '#384967', borderColor: '#384967', color: '#fff'}} danger>Dapatkan Promo</Button>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default PurchasePlanSection
