import React, {Component} from 'react';
import {HomeStates} from "./types/HomeTypes";
import {withRouter} from "../../hoc/withRouter";

class Home extends Component<any, HomeStates> {
    private readonly initState!: HomeStates;

    constructor(props: any) {
        super(props);
        document.title = "Trimitra";

        this.initState = {
            fetching: true,
        }
        this.state = {...this.initState}
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div className="imgz">
                    <div className="section sectionz">
                        <div style={{fontSize: "450%"}}><b>WE BUILD</b></div>
                        <div style={{fontSize: "260%"}}><b>POWERFUL DIGITAL DEALER</b></div>
                        <div style={{marginTop: 10}}>Using Digital Branding and Communication to empower your dealership.</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Home)