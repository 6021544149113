import Cookies from "universal-cookie"
import moment from "moment"
import axios from "axios"
import {baseUrlLogBq} from "../../config/apiConfig/apiConfig"
import {getUA, osName, osVersion, browserName, browserVersion, deviceType, mobileVendor, mobileModel} from 'react-device-detect'
import {appProduction} from "../../config/appDataConfig/appConfig"

const randomUUID = moment().format("YYYYMMDDHHmmss") + "-" + (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)

class LiveBqLogService {
    private axios = axios.create({
        baseURL: baseUrlLogBq,
    })

    public async initTrackLog(params?: any) {
        await this.sendTrackLog(params)
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const cookies = new Cookies()
                let latCookies = cookies.get('_lat') ?? null
                let lngCookies = cookies.get('_lng') ?? null
                let ipCookies = cookies.get('_ip') ?? null
                let uniqueDeal = cookies.get('_ud') ?? null
                if (!latCookies || !lngCookies) {
                    cookies.set('_lat', position.coords.latitude, {maxAge: 216000})
                    cookies.set('_lng', position.coords.longitude, {maxAge: 216000})
                }
                if (!ipCookies) {
                    cookies.set('_ip', null, {maxAge: 216000})
                }
                if (!uniqueDeal) {
                    cookies.set('_ud', randomUUID, {maxAge: 716000})
                }
                params.category = "allow_location"
                await this.sendTrackLog(params)
            },
            async (err) => {
                // await this.sendTrackLog(params)
            }
        )
    }

    sendTrackLog = async (params: any) => {
        const cookies = new Cookies()
        let latCookies = cookies.get('_lat') ?? null
        let lngCookies = cookies.get('_lng') ?? null
        let ipCookies = cookies.get('_ip') ?? null
        const ipAddr = (ipCookies === "null") ? null : ipCookies
        let uniqueDeal = cookies.get('_ud') ?? null
        if (!uniqueDeal) {
            cookies.set('_ud', randomUUID, {maxAge: 716000})
        }

        const dataBody = {
            company_code: params?.companyCode?.toUpperCase(),
            city_group: params?.cityGroup?.toUpperCase(),
            event: {
                time: moment().format("YYYY-MM-DD HH:mm:ss"),
                source_url: params?.sourceUrl,
                site: params?.site,
                category: params?.category,
                name: params?.name,
                parameter: params?.parameter,
                score: (params?.score) ? Number(params?.score) : null,
            },
            user_data: {
                client_ip_address: ipAddr,
                client_user_agent: getUA?.toString(),
                client_os: osName?.toString()?.toLowerCase() ?? null,
                client_os_version: osVersion?.toString()?.toLowerCase() ?? null,
                client_browser_name: browserName?.toString()?.toLowerCase() ?? null,
                client_browser_type: browserVersion?.toString()?.toLowerCase() ?? null,
                client_device_type: deviceType?.toString()?.toLowerCase() ?? null,
                client_device_vendor: mobileVendor?.toString()?.toLowerCase() ?? null,
                client_device_model: mobileModel?.toString()?.toLowerCase() ?? null,
                cookies: {
                    deal: uniqueDeal,
                    deal_season: params?.offerCode ?? null
                },
                location_latitude: parseFloat(latCookies),
                location_longitude: parseFloat(lngCookies)
            },
            custom_data: {
                search_target: null,
                search_info: null,
                campaign_code: params?.dealCode ?? null,
                campaign_data: params?.dealData ?? null,
            },
            vehicle: (params?.vehicle?.brand_uuid) ? params?.vehicle : null,
            id_card_order_maker: (params?.id_card_order_maker?.full_name) ? params?.id_card_order_maker : null,
            address_order_maker: (params?.address_order_maker?.province_code) ? params?.address_order_maker : null,
        }

        if (appProduction) {
            try {
                await this.axios.post<any>('/log/digital-dealer.tech',
                    dataBody, {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Api-Key": ""
                        }
                    })
            } catch (e: any) {
                console.log(e)
            }
        } else {
            console.log(dataBody)
        }
    }
}

export const liveBqLogService = new LiveBqLogService()