import React, {Component} from 'react'
import {PromoStates} from "./types/PromoTypes"
import {withRouter} from "../../hoc/withRouter"
import PageHeader from "../../component/header/PageHeader"
import FooterSection from "../../component/pageSection/FooterSection"
import {Breadcrumb, Badge, Button, Checkbox, Col, Empty, Row, Select, Spin} from "antd"
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices"
import {promoServices} from "../../services/promo/PromoService"
import CardPromo from "../../component/card/CardPromo"
import Search from "antd/es/input/Search"
import Cookies from "universal-cookie"
import companyCodeHelper from "../../helper/companyCode"

class Promo extends Component<any, PromoStates> {
    private cookies = new Cookies()
    private readonly initState!: PromoStates

    constructor(props: any) {
        super(props)

        const {match} = this.props
        const checkCompany = companyCodeHelper({hostName: window.location.host, companyCode: match?.params?.companyCode?.toUpperCase()})
        const companyCode = checkCompany.companyCode
        const companyType = checkCompany.companyType

        this.initState = {
            companyCode: companyCode,
            companyType: companyType,
            companyData: {},
            areaCode: "",
            isDesktop: false,
            fetching: true,
            methodSelected: "all",
            modelSelected: "all",
            allowAgent: false,
            listModel: [],
            dataPromo: [],
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            })
        } else {
            this.setState({
                isDesktop: true,
            })
        }
    }

    onFieldChange = <T extends keyof Pick<any, "methodSelected" | "modelSelected" | "allowAgent">>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "methodSelected":
                currentState.methodSelected = value
                break
            case "modelSelected":
                currentState.modelSelected = value
                break
            case "allowAgent":
                currentState.allowAgent = value
                break
        }

        this.setState({
            ...currentState,
        })
    }

    fetchAllModelProduct = async () => {
        try {
            const result = await trimitraCatalogServices.getCatalogModel({companyCode: this.state.companyCode, areaCode: this.state.areaCode})
            this.setState({
                listModel: result.data.data ?? []
            })
        } catch (e) {
            this.setState({
                listModel: []
            })
        }
    }

    fetchDetailPromo = async (data: string) => {
        if (data) {
            this.setState({fetching: true})
            try {
                const x = []
                const fetchDetail = await promoServices.getInfoDetailPromo({
                    companyCode: this.state.companyCode,
                    promoCode: data?.trim()
                })
                x.push(fetchDetail?.data?.data)
                this.setState({
                    dataPromo: x,
                    fetching: false,
                })
            } catch (e) {
                this.setState({
                    dataPromo: [],
                    fetching: false,
                })
            }
        }
    }

    fetchPromoData = async (fetchType: string) => {
        await this.promisedSetState({
            fetching: true,
        })
        try {
            const result = await promoServices.getPromo({
                companyCode: this.state.companyCode,
                cityGroup: this.state.areaCode,
                purchaseMethod: (this.state.methodSelected !== 'all') ? this.state.methodSelected : "",
                model: (this.state.modelSelected !== 'all') ? this.state.modelSelected : "",
                allowAgent: this.state.allowAgent,
                log: false,
            })
            this.setState({
                dataPromo: result?.data?.data,
                fetching: false,
            })
        } catch (e) {
            this.setState({
                dataPromo: [],
                fetching: false,
            })
        }
    }

    async componentDidMount() {
        await this.onFetchCompany()
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 10)

        window.addEventListener("resize", this.handleResize.bind(this))
        this.handleResize()
        this.fetchAllModelProduct()
        this.fetchPromoData("fetch")
    }

    onFetchCompany = async () => {
        try {
            const dataDetail = await trimitraCatalogServices.getDetailProject({companyCode: this.state.companyCode})
            document.title = dataDetail.data?.data?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())
            const favicon: any = document.querySelector('link[rel="icon"]')
            if (favicon) {
                favicon.href = dataDetail.data.data.logo
            }

            let defaultArea = this.state.areaCode
            if (!this.cookies.get(`_da-${this.state.companyCode.toLowerCase()}`)) {
                defaultArea = dataDetail.data.data.default_city_group
            } else {
                defaultArea = this.cookies.get(`_da-${this.state.companyCode.toLowerCase()}`)
            }

            await this.promisedSetState({
                companyData: dataDetail.data.data,
                areaCode: defaultArea
            })
        } catch (e) {
            window.location.href = "/trimitra"
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set(`_da-${this.state.companyCode.toLowerCase()}`, data.city_group)
        await this.promisedSetState({areaCode: data.city_group})
        await this.fetchPromoData("fetch")
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <React.Fragment>
                <PageHeader companyType={this.state.companyType} companyData={this.state.companyData} black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>{this.state.companyData?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())}</Breadcrumb.Item>
                            <Breadcrumb.Item>Promo</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>


                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Promo {this.state.companyData?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())}</div>
                                <br/>
                                <div className={`detail-product-caption`}>Dapatkan penawaran terbaik area {this.state.areaCode}, gunakan fitur pencarian untuk mendapatkan promo yang diinginkan.</div>
                                <br/>
                                <Select
                                    bordered={false}
                                    value={this.state.methodSelected}
                                    onChange={event => this.onFieldChange("methodSelected", event)}
                                    style={{fontSize: "80%", width: 140, paddingTop: 2, paddingBottom: 2}}
                                >
                                    <Select.Option value="all">Semua Metode</Select.Option>
                                    <Select.Option value="cash">Tunai</Select.Option>
                                    <Select.Option value="credit">Kredit</Select.Option>
                                </Select>
                                <Select
                                    bordered={false}
                                    onChange={event => this.onFieldChange("modelSelected", event)}
                                    value={this.state.modelSelected}
                                    style={{fontSize: "80%", width: 140, paddingTop: 2, paddingBottom: 2}}
                                >
                                    <Select.Option value="all">Semua Model</Select.Option>
                                    {this.state.listModel.map((item: any, i: number) =>
                                        <Select.Option key={i} value={item.model_name}>{item.model_name}</Select.Option>
                                    )}
                                </Select>
                                <Checkbox checked={this.state.allowAgent} onChange={e => this.onFieldChange("allowAgent", e.target.checked)}>
                                    <span style={{fontSize: "80%"}}>Agen</span>
                                </Checkbox>
                                <br/><br/>
                                <Button onClick={() => this.fetchPromoData("log")} danger type="primary">Temukan Promo</Button>
                            </Col>
                            <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/Wallet_Monochromatic.png`} style={{height: 200}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div style={this.state.fetching ? {textAlign: "center", paddingTop: "250px", paddingBottom: "200px"} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div style={!this.state.fetching ? {} : {display: 'none'}}>
                    <Row className={`p-20 mt-10`}>
                        <Col xxl={{span: 14, offset: 5}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Search className={`p-10`} placeholder="masukan kode promo" onSearch={this.fetchDetailPromo} style={{width: 300}}/>

                            <Row>
                                {this.state.dataPromo.map((item: any, i: number) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                                <Badge.Ribbon color={(item?.purchase_method[0] === "credit" ? "#64b48f" : "blue")} text={(item?.purchase_method[0] === "credit" ? "Kredit" : "Tunai")}>
                                                    <CardPromo data={item}/>
                                                </Badge.Ribbon>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })}
                                <div style={this.state.dataPromo?.length < 1 ? {textAlign: "center", paddingLeft: `${(this.state.isDesktop) ? "40%" : "20%"}`} : {display: 'none'}}>
                                    <Empty/>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div style={{minHeight: 200}}/>
                <FooterSection companyData={this.state.companyData}/>
            </React.Fragment>
        )
    }
}

export default withRouter(Promo)