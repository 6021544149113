import React, {Component} from "react"
import './HomeBanner.css'

class HomeBanner extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    render() {
        return (
            <React.Fragment>
                <div style={{
                    width: "100%",
                    height: "200",
                    backgroundImage: `url('${this.props.backgroundImage}')`,
                    backgroundAttachment: `${this.props.backgroundAttachment}`,
                }} className={this.props.imageCode}>
                    {/*<div className="section section-1" style={this.props.imageCode === "img-1" ? {fontSize: `${this.props.isDesktop ? "100%" : "80%"}`} : {display: 'none'}}>
                        <h2 style={{textAlign: "left", marginLeft: `${this.props.isDesktop ? "105vh" : "1vh"}`, padding: `${this.props.isDesktop ? "40px" : "10px"}`}}>
                            <div style={{fontSize: "120%"}}>
                                Promo Spesial Chery
                            </div>
                            <div style={{fontSize: "80%", paddingTop: 20, paddingBottom: 20, fontWeight: 400}}>
                                Dapatkan penawaran terbaik Chery Bandung 2023, <br/>DP & Cicilan Ringan, serta keuntungan lainnya.
                            </div>
                            <Link to={`/promo`}>
                                <Button size={"large"}>Dapatkan Promo</Button>
                            </Link>
                        </h2>
                    </div>*/}
                </div>
            </React.Fragment>
        )
    }
}

export default HomeBanner
