import React, {Component} from 'react';
import {SpkStates} from "./types/SpkTypes";
import {withRouter} from "../../hoc/withRouter";
import {Alert, Breadcrumb, Col, message, Result, Row, Tooltip} from "antd";
import Cookies from "universal-cookie";
import CopyOutlined from "@ant-design/icons/CopyOutlined";

class Promo extends Component<any, SpkStates> {
    private cookies = new Cookies();
    private readonly initState!: SpkStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const offerCode = match.params.offercode ?? "";

        this.initState = {
            isDesktop: false,
            offerCode: offerCode,
            fetching: true,
            methodSelected: "all",
            modelSelected: "all",
            allowAgent: false,
            listModel: [],
            dataPromo: [],
            areaCode: "jawabarat",
            processing: false,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }


    componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Nomor ESPK berhasil dicopy');
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Dealer</Breadcrumb.Item>
                            <Breadcrumb.Item>SPK Result</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>


                <Row className={`mt-20`} style={{backgroundColor: "#fff"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div className={`content-top-padding-20 height-100`}>
                            <Result
                                status="success"
                                title="Pengisian Data Sukses"
                                subTitle="Silakan tunggu konfirmasi dari kami untuk proses selanjutnya"
                            />
                            <br/>

                            Nomor Pengajuan (ESPK): {this.state.offerCode}
                            <Tooltip title="Click untuk copy nomor SPK" color={'cyan'}>
                                <CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.offerCode)}/>
                            </Tooltip> <br/> <br/>

                            <Alert
                                message="Informasi"
                                description={`Untuk memudahkan pelacakan dan informasi terkait transaksi anda, kami menghimbau anda untuk menyimpan nomor SPK. click untuk copy`}
                                type="info"
                                style={{cursor: "pointer"}}
                                showIcon
                                onClick={event => this.copyToClipboard(this.state.offerCode)}
                            /><br/>
                        </div>
                    </Col>
                </Row>

                <div style={{minHeight: 200}}/>
            </React.Fragment>
        );
    }
}

export default withRouter(Promo);
