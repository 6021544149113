import axios from "axios";
import {Buffer} from "buffer";
import {baseUrlTrimitraCatalog, xApiKeyTrimitraCatalog} from "../../config/apiConfig/apiConfig";
import {TrimitraBaseResponse} from "./types/trimitraCatalogTypes";
import {ICityModel} from "./types/cityTypes";
import {IVariant} from "./types/variantTypes";
import {IModel} from "./types/modelTypes";

class TrimitraCatalogServices {
    private axios = axios.create({
        baseURL: baseUrlTrimitraCatalog,
    });

    public async getDetailProject(params: any) {
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>(`/project/${params.companyCode}`, {
                headers: {"X-Api-Key": xApiKeyTrimitraCatalog}
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCityGroup(params: any) {
        const queries = {
            ...(params?.area && {
                area: params.area,
            }),
            ...(params?.name && {
                name: params.name,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<ICityModel[]>>(`/area/${params.companyCode}/city_group`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCurationHomepage(params?: { companyCode?: string, areaCode?: string }) {
        try {
            return await this.axios.get<any>(`curations/${params?.companyCode?.toLowerCase()}/citygroup/${params?.areaCode?.toLowerCase()}/curation_homepage`,
                {
                    headers: {
                        "X-Api-Key": xApiKeyTrimitraCatalog,
                    }
                });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getListCatalog(params?: { companyCode?: string, areaCode?: any, code?: string }) {
        try {
            return await this.axios.get<TrimitraBaseResponse<IModel[]>>(`catalogs/${params?.companyCode?.toLowerCase()}/citygroup/${params?.areaCode?.toLowerCase()}/${params?.code?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogVariant(params?: { companyCode?: string, areaCode?: string, modelName?: string, variantCode?: string, homeShowed?: boolean }) {
        const queries = {
            ...(params?.modelName && {
                model_name: params.modelName,
            }),
            ...(params?.variantCode && {
                code: params.variantCode,
            }),
            ...(params?.homeShowed && {
                home_showed: params.homeShowed,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<IVariant[]>>(`products/${params?.companyCode}/citygroup/` + params?.areaCode?.toLowerCase() + '/variant', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogModel(params?: { companyCode?: string, areaCode?: string, modelClass?: string, homeShowed?: boolean }) {
        const queries = {
            ...(params?.modelClass && {
                class: params.modelClass,
            }),
            ...(params?.homeShowed && {
                home_showed: params.homeShowed,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<IModel[]>>(`products/${params?.companyCode}/citygroup/` + params?.areaCode?.toLowerCase() + '/model', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            console.log(e.response.data.error.message);
            throw new Error(e.response.data.error.message);
        }
    }

    public async getCatalogMokas(params?: { areaCode?: string, mokasCode?: string }) {
        const queries = {
            ...(params?.mokasCode && {
                license_plate: Buffer.from(params.mokasCode, 'base64').toString().replace(/ /g, ''),
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>('products/chery/citygroup/' + params?.areaCode?.toLowerCase() + '/used', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogDetailVariant(params?: { companyCode?: string, variantCode?: string }) {
        const queries = {
            ...(params?.variantCode && {
                code: params.variantCode,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>(`/detail-products/${params?.companyCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getCatalogMaster(params?: { companyCode?: string, cityGroup?: string, active?: boolean, destinationLink?: string }) {
        const queries = {
            company: params?.companyCode,
            ...(params?.cityGroup && {
                city_group: params.cityGroup,
            }),
            ...(params?.destinationLink && {
                destination_link: params.destinationLink,
            }),
            ...(params?.active && {
                active: params.active,
            }),
        }
        try {

            return await this.axios.get<TrimitraBaseResponse<any>>('/catalog/master', {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getSimulationCreditFinco(params?: { areaCode?: string, vehicleModel?: string, fincoCode?: string }) {
        const queries = {
            ...(params?.vehicleModel && {
                vehicle_model: params.vehicleModel,
            }),
        }
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>(`credit-simulation/config/chery/${params?.fincoCode?.toLowerCase()}/${params?.areaCode?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getStock(params?: { areaCode?: string, vehicleVariant?: string }) {
        const queries = {
            ...(params?.vehicleVariant && {
                code: params.vehicleVariant,
            }),
        }
        try {
            return await this.axios.get<any>(`stock/chery/${params?.areaCode?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getListMicroPage(params?: any) {
        const queries = {
            ...(params?.vehicleModel && {
                tags: params.tags,
            }),
        }
        try {
            return await this.axios.get<any>(`/micro-page/chery`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getDetailMicroPage(params?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/micro-page/chery/${params.title}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getAdditionalCostOrder(params?: { purchaseMethod?: string, cityGroup?: any, model?: any }) {
        try {
            return await this.axios.get<TrimitraBaseResponse<any>>(`/additional-order-cost/chery?city_group=${params?.cityGroup?.toLowerCase()}&type=model&model=${params?.model?.toLowerCase()}&purchase_method=${params?.purchaseMethod?.toLowerCase()}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getDealList(params?: any) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/deal/chery?show=true&active=true`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getPreBooking() {
        const queries = {}
        try {
            return await this.axios.get<any>(`/pre-booking/chery`, {
                headers: {
                    "X-Api-Key": xApiKeyTrimitraCatalog,
                    "Content-Type": "application/json"
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

}

export const trimitraCatalogServices = new TrimitraCatalogServices();
