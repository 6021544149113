import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Provider} from "react-redux"
import {store} from './redux'
import Home from "./screen/home/Home"
import Homepage from "./screen/home/Homepage"
import Promo from "./screen/promo/Promo"
import Test from "./screen/test/Test"
import Products from "./screen/products/Products"
import Variant from "./screen/products/Variant"
import PurchasePlan from "./screen/products/PurchasePlan"
import Spk from "./screen/spk/Spk"
import NotFound from "./screen/home/NotFound"

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/test" caseSensitive={false} element={<Test/>}/>
                    <Route path="/trimitra" caseSensitive={false} element={<Home/>}/>

                    <Route path={"/variant/:param/:param2/purchase-plan"} caseSensitive={false} element={<PurchasePlan/>}/>
                    <Route path={"/variant/:param"} caseSensitive={false} element={<Variant/>}/>
                    <Route path={"/product/:param"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/product"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/promo"} caseSensitive={false} element={<Promo/>}/>

                    <Route path={"/:companyCode/variant/:param/:param2/purchase-plan"} caseSensitive={false} element={<PurchasePlan/>}/>
                    <Route path={"/:companyCode/variant/:param"} caseSensitive={false} element={<Variant/>}/>
                    <Route path={"/:companyCode/product/:param"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/:companyCode/product"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/:companyCode/promo"} caseSensitive={false} element={<Promo/>}/>

                    <Route path={"/:companyCode"} caseSensitive={false} element={<Homepage/>}/>
                    <Route path={"/spk/:offercode"} caseSensitive={false} element={<Spk/>}/>
                    <Route path="/" caseSensitive={false} element={<Homepage/>}/>

                    <Route path="*" element={<NotFound/>}/>
                    <Route path="/notfound" element={<NotFound/>}/>
                </Routes>
            </Router>
        </Provider>
    )
}

export default App
