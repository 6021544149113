import React, {Component} from 'react'
import {HomepageStates} from "./types/HomepageTypes"
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices"
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined'
import {withRouter} from "../../hoc/withRouter"
import PageHeader from "../../component/header/PageHeader"
import HomeBanner from "../../component/pageSection/HomeBanner"
import FooterSection from "../../component/pageSection/FooterSection"
import {Col, Divider, Empty, Modal, Row, Spin} from "antd"
import Cookies from "universal-cookie"
import {Link} from "react-router-dom"
import CardProduct from "../../component/card/CardProduct"
import companyCodeHelper from "../../helper/companyCode"
import {liveBqLogService} from "../../services/track/LiveBqLogService"
import PurchasePlanSection from "../../component/pageSection/PurchasePlanSection"

class Home extends Component<any, HomepageStates> {
    private cookies = new Cookies()
    private readonly initState!: HomepageStates

    constructor(props: any) {
        super(props)

        const {match} = this.props
        const checkCompany = companyCodeHelper({hostName: window.location.host, companyCode: match?.params?.companyCode?.toUpperCase()})
        const companyCode = checkCompany.companyCode
        const companyType = checkCompany.companyType

        this.initState = {
            companyCode: companyCode,
            companyType: companyType,
            areaCode: "",
            companyData: {},
            isDesktop: false,
            fetching: true,
            listHomepageCatalog: [],
            listHomepageCuration: [],
            showModelPurchasePlan: false,
            selectedVariantColorData: {},
            fetchCompany: true
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({isDesktop: false})
        } else {
            this.setState({isDesktop: true})
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this))
        await this.onFetchCompany()
        this.handleResize()
        await this.fetchCityCuration()

        await liveBqLogService.initTrackLog({
            companyCode: this.state.companyCode,
            cityGroup: this.state.areaCode,
            sourceUrl: window.location.href,
            site: window.location.host,
            category: "landing",
            name: "landing",
            parameter: null,
        })
    }

    onVariantColorClick = async (data: any) => {
        this.setState({
            showModelPurchasePlan: true,
            selectedVariantColorData: data
        })
    }

    onAreaChange = async (data: any) => {
        this.cookies.set(`_da-${this.state.companyCode.toLowerCase()}`, data.city_group)
        await this.promisedSetState({areaCode: data.city_group})
        await this.fetchCityCuration()
    }

    onFetchCompany = async () => {
        try {
            const dataDetail = await trimitraCatalogServices.getDetailProject({companyCode: this.state.companyCode})
            document.title = dataDetail.data?.data?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())
            const favicon: any = document.querySelector('link[rel="icon"]')
            if (favicon) {
                favicon.href = dataDetail.data.data.logo
            }

            let defaultArea = this.state.areaCode
            if (!this.cookies.get(`_da-${this.state.companyCode.toLowerCase()}`)) {
                defaultArea = dataDetail.data.data.default_city_group
            } else {
                defaultArea = this.cookies.get(`_da-${this.state.companyCode.toLowerCase()}`)
            }

            await this.promisedSetState({
                companyData: dataDetail.data.data,
                areaCode: defaultArea,
                fetchCompany: false
            })
        } catch (e) {
            window.location.href = "/trimitra"
        }
    }

    fetchCityCuration = async () => {
        await this.promisedSetState({
            fetching: true,
            listHomepageCatalog: [],
            listHomepageCuration: [],
        })

        try {
            const dataCurationHome = await trimitraCatalogServices.getCurationHomepage({
                companyCode: this.state.companyCode,
                areaCode: this.state.areaCode
            })
            const listHomePageCuration = dataCurationHome?.data?.data?.catalog_list ?? []
            const tempFullCatalogResponse: any = []
            await Promise.all(listHomePageCuration.map(async (item: any) => {
                try {
                    const dataCuration = await trimitraCatalogServices.getListCatalog({
                        companyCode: this.state.companyCode,
                        areaCode: this.state.areaCode,
                        code: item.code
                    })
                    tempFullCatalogResponse.push({
                        code: dataCuration?.data?.meta?.code,
                        name: dataCuration?.data?.meta?.name,
                        destinationLink: dataCuration?.data?.meta?.destination_link,
                        displayLayout: dataCuration?.data?.meta?.display_layout,
                        data: dataCuration?.data?.data
                    })
                } catch (e) {
                    console.log(e)
                }
            }))

            const listHomepageCatalog = []
            for (const x of listHomePageCuration) {
                const picked = tempFullCatalogResponse.find((o: { code: string }) => o.code === x?.code)
                listHomepageCatalog.push(picked)
            }

            this.setState({
                listHomepageCatalog: listHomepageCatalog,
                listHomepageCuration: listHomePageCuration,
                fetching: false,
            })
        } catch (e) {
            this.setState({
                fetching: false,
                listHomepageCatalog: [],
                listHomepageCuration: [],
            })
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    render() {
        return (
            <>
                <div style={this.state.fetchCompany ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>
                <div style={!this.state.fetchCompany ? {} : {display: 'none'}}>
                    <PageHeader companyType={this.state.companyType} companyData={this.state.companyData} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>
                    <HomeBanner isDesktop={this.state.isDesktop} imageCode={`img-1`} backgroundImage={this.state.companyData?.home_page?.cover} backgroundAttachment={(this.state.isDesktop) ? `fixed` : ""}/>
                    <Row style={this.state.isDesktop ? {paddingTop: 10, paddingBottom: 80} : {marginTop: -120}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            {!this.state.isDesktop && (<div style={{marginTop: 100}}/>)}
                            {/*<div style={{textAlign: "center", paddingTop: 40, fontSize: "200%", fontWeight: 500, color: "#3f3f3f"}}>*/}
                            {/*    {this.state.companyData?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())} | Dealer & Bengkel Resmi*/}
                            {/*</div>*/}

                            <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>

                            <Row className={`p-20`} style={!this.state.fetching ? {} : {display: 'none'}}>
                                {this.state.listHomepageCatalog.map((listCatalogData: any, x: number) =>
                                    <React.Fragment key={x}>
                                        <div className={`chosen-product pb-20`}>
                                            <span className={`chosen-product-text ${(this.state.isDesktop) ? "font-140" : ""}`}>{listCatalogData.name}</span>
                                            {listCatalogData.destinationLink === 'variant' &&
                                                <Link to={`/${this.state.companyCode?.toLowerCase()}/product/${listCatalogData.code}`}><span className={`chosen-product-other`}> Lihat Semua <DoubleRightOutlined/></span></Link>
                                            }
                                        </div>

                                        {listCatalogData.data.map((item: any, i: number) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <Col className={`p-10`} xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}}>
                                                        <CardProduct companyType={this.state.companyType} onVariantColorClick={this.onVariantColorClick} companyData={this.state.companyData} areaCode={this.state.areaCode} data={item}/>
                                                    </Col>
                                                </React.Fragment>
                                            )
                                        })}
                                        <Divider/>
                                    </React.Fragment>
                                )}
                            </Row>
                            <div style={!this.state.fetching && this.state.listHomepageCuration?.length < 1 ? {textAlign: "center"} : {display: 'none'}}>
                                <Empty/>
                            </div>
                        </Col>
                    </Row>
                    <FooterSection companyData={this.state.companyData}/>

                    <Modal
                        open={this.state.showModelPurchasePlan}
                        onCancel={() => this.setState({showModelPurchasePlan: false})}
                        footer={[]}
                    >
                        <PurchasePlanSection dataVariantColor={this.state.selectedVariantColorData} companyData={this.state.companyData} areaCode={this.state.areaCode}/>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(Home)