import React, {Component} from "react"
import {Button, Card, Row} from "antd"
import SwapRightOutlined from '@ant-design/icons/SwapRightOutlined'
import Col from "antd/lib/col"
import {Link} from "react-router-dom"
import currencyMillionFormat from "../../helper/currencyMillionFormat"
import {liveBqLogService} from "../../services/track/LiveBqLogService"

class CardProduct extends Component<any, any> {
    private readonly initState!: any

    constructor(props: any) {
        super(props)

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    onclickCard = async (params: any) => {
        if (params.data.entity_type === "variant_color") {
            this.props.onVariantColorClick(params.data)
        }

        await liveBqLogService.sendTrackLog({
            companyCode: this.props.companyData?.code,
            cityGroup: this.props.areaCode,
            sourceUrl: window.location.href,
            site: window.location.host,
            category: "card_vehicle",
            name: params.data.variant_code + "|" + params.data.color_code,
            parameter: params.data.variant_code + "|" + params.data.color_code,
            vehicle: {
                brand_uuid: params.data.brand_uuid,
                brand_name: params.data.brand_name,
                model_name: params.data.model_name,
                model_uuid: params.data.model_uuid,
                variant_name: params.data.variant_name,
                variant_code: params.data.variant_code,
                color_code: params.data.color_code,
                color_name: params.data.color_name,
            },
        })
    }

    render() {
        let cardName = ""
        let url = (this.props.companyType === "private") ? `/product` : `/${this.props?.companyData?.code?.toLowerCase()}/product`

        if (this.props.data?.entity_type === "variant") {
            cardName = this.props.data?.variant_name
            url = (this.props.companyType === "private") ? `/variant/${this.props.data?.variant_code?.replace("/", "-")}` : `/${this.props?.companyData?.code?.toLowerCase()}/variant/${this.props.data?.variant_code?.replace("/", "-")}`
        }

        if (this.props.data?.entity_type === "variant_color") {
            cardName = this.props.data?.variant_name
            url = "#"
        }

        if (this.props.data?.entity_type === "model") {
            cardName = this.props.data?.model_name
        }
        if (this.props.data?.entity_type === "catalog") {
            cardName = this.props.data?.catalog_name
        }

        return (
            <React.Fragment>
                <Link to={url}>
                    <Card
                        onClick={() => this.onclickCard(this.props)}
                        hoverable
                        style={{width: "100%"}}
                        cover={<img alt="vacancy-logo" src={this.props.data?.url_image}/>}
                    >
                        <Row>
                            <Col span={24}>
                                <span className={`card-product-title`}>{cardName?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span><br/>
                                <div style={this.props.data?.entity_type === "variant" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <span style={this.props.data?.price === 0 ? {} : {display: 'none'}} className={`card-product-price`}>Not Available</span>
                                    <span className={`card-product-price`} style={{fontSize: "80%"}}>Harga</span><br/>
                                    <span style={this.props.data?.price !== 0 ? {} : {display: 'none'}} className={`card-product-price`}>
                                        <b>{currencyMillionFormat(this.props.data?.price) ?? "-"}</b>
                                    </span>
                                    <Button size={`small`} style={{float: "right", fontSize: "80%"}}>Selengkapnya <SwapRightOutlined style={{color: "darkred"}}/></Button>
                                </div>

                                <div style={this.props.data?.entity_type === "variant_color" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <span style={this.props.data?.price === 0 ? {} : {display: 'none'}} className={`card-product-price`}>Not Available</span>
                                    <span className={`card-product-price`} style={{fontSize: "80%"}}>Harga</span><br/>
                                    <span style={this.props.data?.price !== 0 ? {} : {display: 'none'}} className={`card-product-price`}>
                                        <b>{currencyMillionFormat(this.props.data?.price) ?? "-"}</b>
                                    </span>
                                    <Button size={`small`} style={{float: "right", fontSize: "80%"}}>Selengkapnya <SwapRightOutlined style={{color: "darkred"}}/></Button>
                                </div>

                                <div style={this.props.data?.entity_type === "model" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <span className={`card-product-price`}>
                                        {currencyMillionFormat(this.props.data?.price_min) ?? "-"} - {currencyMillionFormat(this.props.data?.price_max) ?? "-"}
                                    </span>
                                </div>

                                <div style={this.props.data?.entity_type === "catalog" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <Button danger>Lihat Katalog</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Link>
            </React.Fragment>
        )
    }
}

export default CardProduct