/** PRODUCTION LINK */
const baseUrlTrimitraCatalog = "https://zvu1c5uoue.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAmartaVip = "https://3krjkubmjk.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseUrlCdnUpload = "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAutotrimitra = "https://au-api-trimitra-get-65q45htc.ts.gateway.dev";
const baseUrlPromo = "https://80pj23grqd.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlOffer = "https://42cjbxpaa8.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlB2b = "https://asia-southeast2-amh-b2b.cloudfunctions.net/api-b2b";
const baseUrlLogBq = "https://asia-southeast2-autotrimitra.cloudfunctions.net/activityLog-AmartahondaBQ";
const baseUrlIdealTrimitra = "https://asia-southeast2-ideal-trimitra.cloudfunctions.net/ideal-backend";

/** KEY */
const xApiKeyTrimitraCatalog = '5ukoYcllpl6lIeKsbeIPI4hOZGDszFVk1dDBddHi';
const xApiKeyAmartaVip = 'wbEBxCen12pHWwBHMakz3ebKWmttdwr9BfYrZlw2';
const xApiKeyCdnUpload = 'zXSa4iZwycS0O5kaoMvi2yAFiIpimTZ55MvvJLy0';
const xApiKeyPromo = 'itMzx2LEJU2VtfzkBsZXs7U4p2ScXXXn4qY3Xdbw';
const xApiKeyOffer = '9bekwohY878MgiMsRL0Wk2Xsgv4QsxtW4jEIuBqb';

export {
    baseUrlLogBq, baseUrlAmartaVip, baseUrlB2b, xApiKeyAmartaVip, baseUrlCdnUpload, xApiKeyCdnUpload, baseUrlTrimitraCatalog, xApiKeyTrimitraCatalog, baseUrlAutotrimitra, baseUrlPromo, xApiKeyPromo, baseUrlOffer, xApiKeyOffer, baseUrlIdealTrimitra
}
