import React, {Component} from "react"
import './FooterSection.css'
import {Row} from "antd"
import Col from "antd/lib/col"
import {FacebookOutlined, YoutubeOutlined, TikTokOutlined, TwitterOutlined, InstagramOutlined, EnvironmentOutlined, PhoneOutlined} from "@ant-design/icons"
import {Link} from "react-router-dom"
import {appVersion} from "../../config/appDataConfig/appConfig"

class FooterSection extends Component<any, any> {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-section">
                    <div className="container">
                        <div className="footer-content pt-5 pb-5">
                            <Row>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-logo">
                                            <Link to={`/`}>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <img style={{height: 30, paddingLeft: 15, marginTop: 20, cursor: "pointer"}} className={`header-logo`} src={this.props.companyData?.logo} alt={`logo`}/>
                                                        </td>
                                                        <td>
                                                            <div style={{color: "#fff", paddingTop: 8, paddingLeft: 5, fontSize: "150%"}}><b>{this.props.companyData?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())}</b></div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </Link>
                                        </div>
                                        <div className="footer-text">
                                            <p><b>{this.props.companyData?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())}</b> {this.props.companyData?.description}</p>
                                        </div>
                                        <div className="footer-social-icon">
                                            <div className="footer-widget-heading">
                                                <h3>Follow Us</h3>
                                            </div>

                                            {this.props.companyData?.social_media?.instagram && (
                                                <a href={this.props.companyData?.social_media?.instagram} target="_blank" rel="noreferrer">
                                                    <InstagramOutlined/>
                                                </a>
                                            )}
                                            {this.props.companyData?.social_media?.facebook && (
                                                <a href={this.props.companyData?.social_media?.facebook} target="_blank" rel="noreferrer">
                                                    <FacebookOutlined/>
                                                </a>
                                            )}
                                            {this.props.companyData?.social_media?.tiktok && (
                                                <a href={this.props.companyData.tiktok} target="_blank" rel="noreferrer">
                                                    <TikTokOutlined/>
                                                </a>
                                            )}
                                            {this.props.companyData?.social_media?.x && (
                                                <a href={this.props.companyData.x} target="_blank" rel="noreferrer">
                                                    <TwitterOutlined/>
                                                </a>
                                            )}
                                            {this.props.companyData?.social_media?.youtube && (
                                                <a href={this.props.companyData.youtube} target="_blank" rel="noreferrer">
                                                    <YoutubeOutlined/>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading">
                                            <h3>Useful Links</h3>
                                        </div>
                                        <ul>
                                            <li><a href={`/${this.props.companyData?.code?.toLowerCase()}`}>Home</a></li>
                                            <li><a href={`https://api.whatsapp.com/send?phone=${this.props.companyData?.whatsapp}`} target={`_blank`}>Chat With Us</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div>
                                            <EnvironmentOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Find us</h4>
                                                {(this.props?.companyData?.address || []).map((item: any, i: number) =>
                                                    <span key={i}>{item}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="single-cta">
                                            <PhoneOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Call us</h4>
                                                <span>{this.props.companyData?.phone_number}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="copyright-area p-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                    <div className="copyright-text">
                                        <p>Copyright &copy {new Date().getFullYear()} {appVersion}, All Right Reserved <span style={{color: "#E83939FF"}}><a href={`https://trimitra.biz`}>PT TRIMITRA SUKSES</a></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default FooterSection