export default function companyCode(params: any) {
    // if (params.hostName === "localhost:3000") {
    //     return {
    //         companyCode: "amarta",
    //         companyType: "private"
    //     }
    // }
    if (params.hostName === "motorhondabangka.com") {
        return {
            companyCode: "bangkamotorhonda",
            companyType: "private"
        }
    }

    return {
        companyCode: params.companyCode,
        companyType: "hosting"
    }
}