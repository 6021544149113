import React, {Component} from "react"
import './PageHeader.css'
import EnvironmentFilled from "@ant-design/icons/EnvironmentFilled"
import DownOutlined from "@ant-design/icons/DownOutlined"
import Cookies from "universal-cookie"
import {Button, Col, Divider, Drawer, Row} from "antd"
import {liveBqLogService} from "../../services/track/LiveBqLogService"
import {Link} from "react-router-dom"
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices"

class PageHeader extends Component<any, any> {
    private readonly initState!: any
    private cookies = new Cookies()

    constructor(props: any) {
        super(props)

        this.initState = {
            loading: false,
            headerScrollDown: false,
            showMobileMenu: false,
            openDrawer: false,
            listCityGroup: [],
        }
        this.state = {...this.initState}
    }

    onClickMobileLeftMenu = async () => {
        this.setState({
            showMobileMenu: (!this.state.showMobileMenu),
        })
    }

    onclickMenu = async (param: string) => {
        await liveBqLogService.sendTrackLog({
            companyCode: this.props.companyData?.code,
            sourceUrl: window.location.href,
            site: window.location.host,
            category: "click_menu",
            name: param,
            parameter: param,
        })
    }

    async componentDidMount() {
        window.addEventListener('scroll', this.handleAppScroll)
        await this.fetchCityGroup()
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props?.companyData?.code !== prevProps?.companyData?.code) {
            await this.fetchCityGroup()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleAppScroll)
    }

    fetchCityGroup = async () => {
        if (this.props.companyData?.code) {
            try {
                const listCityGroup = await trimitraCatalogServices.getCityGroup({companyCode: this.props.companyData?.code})
                this.setState({
                    listCityGroup: listCityGroup.data.data
                })
            } catch (e) {
                console.log(e)
            }
        }
    }

    handleAppScroll = () => {
        if (window.scrollY >= 85) {
            this.setState({
                headerScrollDown: true
            })
        } else {
            this.setState({
                headerScrollDown: false
            })
        }
    }

    showChangeLocationDrawer = async () => {
        this.setState({
            openDrawer: true,
        })
    }

    changeLocation = async (data: any) => {
        this.props.onAreaChangeProps(data)
        this.setState({
            openDrawer: false,
        })

        await liveBqLogService.sendTrackLog({
            companyCode: this.props.companyData?.code,
            sourceUrl: window.location.href,
            site: window.location.host,
            category: "change_citygroup",
            name: data?.city_group_name,
            parameter: data?.city_group_name,
        })
    }

    render() {
        const c = this.cookies.get('_tc-t')
        const menuHome = (this.props.companyType === "private") ? `/` : `/${this.props?.companyData?.code?.toLowerCase()}`
        const menuPromo = (this.props.companyType === "private") ? `/promo` : `/${this.props?.companyData?.code?.toLowerCase()}/promo`
        const menuProduct = (this.props.companyType === "private") ? `/product` : `/${this.props?.companyData?.code?.toLowerCase()}/product`

        return (
            <>
                <header className={`header headerMenu ${(this.state.headerScrollDown) ? "on-scroll" : (this.props.black) ? "on-scroll" : ""}`} id="header">
                    <nav className="navbar container">
                        <Link to={menuHome} style={{textDecoration: 'none'}}>
                            <div style={{backgroundColor: "rgb(255 255 255 / 88%)", borderRadius: 10, padding: 5}}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <img style={{height: 25, paddingTop: 7}} src={this.props.companyData?.logo} alt={`logo`}/>
                                        </td>
                                        <td>
                                            <div style={{color: "#484848", paddingTop: 5, paddingLeft: 5, fontSize: "150%"}}><b>{this.props.companyData?.name?.toLowerCase()?.replace(/\b\w/g, (s: string) => s?.toUpperCase())}</b></div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Link>
                        <div className={`burger ${(this.state.showMobileMenu) ? "is-active" : ""}`} id="burger" onClick={this.onClickMobileLeftMenu}>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                            <span className={`burger-line ${(this.state.headerScrollDown) ? "background-black" : (this.props.black) ? "background-black" : "background-white"}`}></span>
                        </div>
                        <div className={`menu ${(this.state.showMobileMenu) ? "is-active" : ""}`} id="menu">
                            <ul className="menu-inner">
                                <li onClick={() => this.onclickMenu("home")} className="menu-item" key={1}><a href={menuHome} className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>Home</b></a></li>
                                <li onClick={() => this.onclickMenu("promo")} className="menu-item" key={2}><a href={menuPromo} className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}><b>Promo</b></a></li>
                                <li onClick={() => this.onclickMenu("all_variant")} className="menu-item" key={4}><a href={menuProduct}><Button style={{float: "right"}} danger type="primary">Lihat Semua Variant</Button></a></li>
                            </ul>
                        </div>
                        <div style={{paddingRight: 3, paddingLeft: 10, float: "right"}}>
                            <EnvironmentFilled onClick={this.showChangeLocationDrawer} style={{cursor: "pointer", fontSize: "95%"}} className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}/>
                            <span onClick={this.showChangeLocationDrawer} style={{cursor: "pointer", paddingRight: 7, paddingLeft: 5, fontSize: "95%"}} className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}>Area {this.props.areaCode?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span>
                            <DownOutlined onClick={this.showChangeLocationDrawer} style={{cursor: "pointer", fontSize: "95%"}} className={`menu-item ${(this.state.headerScrollDown) ? "color-black" : (this.props.black) ? "color-black" : "color-white"}`}/>
                        </div>
                        <Link style={!c ? {} : {display: 'none'}} to={`/`}>
                        </Link>
                        <Link style={c ? {} : {display: 'none'}} to={`/my-profile`}>
                            <Button style={{float: "right"}} type="primary">Profile</Button>
                        </Link>
                    </nav>
                </header>

                <Drawer
                    title="Ubah Lokasi"
                    placement={(this.props.isDesktop) ? `right` : `top`}
                    onClose={() => this.setState({openDrawer: false})}
                    open={this.state.openDrawer}
                    // getContainer={false}
                    height={"100vh"}
                    closable={true}
                >
                    <div style={{textAlign: "center"}}>
                        <i style={{color: "#7a7a7a"}}>Lokasi Saat Ini:</i> <br/>
                        <EnvironmentFilled className={`header-home-top`} style={{color: "#ff4d4f"}}/> <b style={{fontSize: "120%", color: "#ff4d4f"}}>Area {this.props.areaCode?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</b> <br/>
                        <div style={{marginTop: 20, color: "#7a7a7a"}}>Pilih area terdekat anda untuk mendapatkan informasi <br/> harga dan promo yang berlaku di area anda.</div>
                    </div>
                    <Divider/>
                    <span style={{color: "#b0b0b0"}}>PILIH LOKASI</span>
                    <div style={{paddingTop: 15}}>
                        <Row>
                            {this.state.listCityGroup.map((item: any, i: number) =>
                                <Col span={12} key={i} style={{padding: 7, cursor: "pointer"}}>
                                    <span onClick={() => {
                                        this.changeLocation(item)
                                    }}><EnvironmentFilled/> {item?.city_group_name.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span><br/>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Drawer>
            </>
        )
    }
}

export default PageHeader
