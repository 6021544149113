import React, {Component} from "react"
import "./VariantColorPreview.css"
import Carousel, {CarouselRef} from "antd/lib/carousel"
import Image from "antd/lib/image"
import Col from "antd/lib/col"
import Row from "antd/lib/row"
import Badge from "antd/lib/badge"
import {liveBqLogService} from "../../services/track/LiveBqLogService"

class VariantColorPreview extends Component<any, any> {
    private readonly initState!: any
    private readonly myRef = React.createRef<CarouselRef>()

    constructor(props: any) {
        super(props)
        this.initState = {
            indexPosition: 0,
            colorName: "",
            colorCode: "",
        }
        this.state = {...this.initState}
    }

    onClickClass = async (indexPosition: number, dataVariantColor: any, type?: string) => {
        this.props.onClickClass(indexPosition, dataVariantColor)
        this.myRef.current?.goTo(indexPosition, false)
        this.setState({
            indexPosition: indexPosition,
            colorName: dataVariantColor?.color_name,
            colorCode: dataVariantColor?.color_code
        })

        if (type !== "init") {
            await liveBqLogService.sendTrackLog({
                companyCode: this.props.companyData?.code,
                cityGroup: this.props.areaCode,
                sourceUrl: window.location.href,
                site: window.location.host,
                category: "card_vehicle",
                name: dataVariantColor.variant_code + "|" + dataVariantColor.color_code,
                parameter: dataVariantColor.variant_code + "|" + dataVariantColor.color_code,
            })
        }
    }

    componentDidMount() {
        this.onClickClass(this.state.indexPosition, this.props.newProductClass[0], "init")
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.fetchData !== prevProps.fetchData) {
            this.onClickClass(this.state.indexPosition, this.props.newProductClass[0], "init")
        }
    }

    render() {
        let textBadge = ""
        let colorBadge = "#64b48f"
        if (this.props.option === 'NEW_VARIANT') {
            if (this.props?.stockData?.data?.custom_availability) {
                textBadge = this.props?.stockData?.data?.custom_availability
            } else {
                const stockColor = this.props?.stockData?.data?.color[this.state.colorCode] ?? 0
                if (stockColor === 0) {
                    textBadge = 'NA / Pre-Order '
                    colorBadge = "#e57373"
                } else if (stockColor < this.props?.stockData?.meta?.availability_limit) {
                    textBadge = 'Tersisa ' + stockColor + ' unit '
                    colorBadge = "#64b48f"
                } else {
                    textBadge = 'Ready '
                    colorBadge = "#2196f3"
                }
            }
        }

        return (
            <div>
                {this.props.option === 'NEW_VARIANT' && this.props.stockData && !this.props.fetchStock &&
                    <Badge.Ribbon style={{zIndex: 1300, fontSize: '110%'}} text={textBadge} color={colorBadge}/>
                }
                <Carousel style={{textAlign: "center"}} ref={this.myRef} initialSlide={this.props.slidePost} swipe={false} dots={false}>
                    {this.props.newProductClass.map((item: any, i: number) =>
                        <div key={i}>
                            <Image width={(this.props.isDesktop) ? `470px` : '100%'} preview={false} className={`carousel-img`} src={item.url_image} alt={`amartahonda-logo`}/>
                        </div>
                    )}
                </Carousel>

                <br/>

                <div className={'colorname-choosen'}>{this.state.colorName}</div>

                <Row justify="center" className={`row-thumb-prev`} style={(this.props?.newProductClass?.length > 4) ? {display: 'none'} : {}}>
                    {this.props.newProductClass.map((item: any, i: number) =>
                        <Col span={5} key={i}>
                            <div className={`card-preview-thumb-product` + (this.state.indexPosition === i ? '-active' : '')}>
                                <div onClick={() => this.onClickClass(i, item)}>
                                    <Image preview={false} src={item.url_image} alt={`amartahonda-logo`}/>
                                    <b style={{display: 'none'}}>{item.color_name}</b>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>

                <div className="outer" style={(this.props?.newProductClass?.length < 5) ? {display: 'none'} : {}}>
                    {this.props.newProductClass.map((item: any, i: number) =>
                        <div key={i} style={{width: 85}} className={`inner card-preview-thumb-product` + (this.state.indexPosition === i ? '-active' : '')}>
                            <div onClick={() => this.onClickClass(i, item)}>
                                <Image preview={false} src={item.url_image} alt={`amartahonda-logo`}/>
                                <b style={{display: 'none'}}>{item.color_name}</b>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default VariantColorPreview
